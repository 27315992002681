import React from "react";
import { useLocation } from "react-router-dom"; // If using React Router

export const Header = () => {
  const location = useLocation(); // If using React Router
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <header id="masthead" className="header prt-header-style-01">
        <div id="site-header-menu" className="site-header-menu">
          <div className="site-header-menu-inner prt-stickable-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                    <div className="site-branding">
                      <a
                        className="home-link"
                        href="/"
                        title="DigitalSignage"
                        rel="home"
                      >
                        <img
                          id="logo-img"
                          className="img-fluid auto_size digital_signage_logo"
                          src="./images/logos/digital_signage_logo.png"
                          alt="logo-img"
                        />
                        <img
                          id="mobile-logo-img"
                          className="img-fluid auto_size mobile_logo"
                          src="./images/logos/digital_signage_logo_mobile.png"
                          alt="mobile-logo-img"
                        />
                      </a>
                    </div>
                    {/* Add Mobile Number below the mobile logo */}
                    <a href="tel:91 91678 60213" className="mobile-number">
                      +91 91678 60213
                    </a>
                    <div className="d-flex flex-row">
                      <div className="btn-show-menu-mobile menubar menubar--squeeze">
                        <span className="menubar-box">
                          <span className="menubar-inner"></span>
                        </span>
                      </div>

                      <nav className="main-menu menu-mobile" id="menu">
                        <ul className="menu">
                          <li
                            className={`nav-item mega-menu-item  ${isActive(
                              "/"
                            )}`}
                          >
                            <a href="/">Home</a>
                          </li>
                          <li
                            className={`nav-item mega-menu-item  ${isActive(
                              "/aboutus"
                            )}`}
                          >
                            <a href="/aboutus">About us</a>
                          </li>
                          <li
                            className={`nav-item mega-menu-item  ${isActive(
                              "/services"
                            )}`}
                          >
                            <a href="/services">Products</a>
                          </li>
                          {/* <li
                            className={`nav-item mega-menu-item  ${isActive(
                              "/projects"
                            )}`}
                          >
                            <a href="/projects">Projects</a>
                          </li> */}
                          <li
                            className={`nav-item mega-menu-item  ${isActive(
                              "/contactus"
                            )}`}
                          >
                            <a href="/contactus">Contact Us</a>
                          </li>
                        </ul>
                      </nav>
                      {/* <div className="header_extra d-flex flex-row align-items-center justify-content-end">
                        <div className="header_btn">
                          <a
                            className="prt-btn prt-btn-size-sm prt-btn-shape-round prt-btn-style-border prt-btn-color-white"
                            href="/contactus"
                          >
                            Lets Talk
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
