import { Home } from "./Pages/Home";
import { Aboutus } from "./Pages/Aboutus";
import { Services } from "./Pages/Services";
import { Servicedetails } from "./Pages/Servicedetails";
import { Contactus } from "./Pages/Contactus";
import { Projects } from "./Pages/Projects";
import { Projectdetails } from "./Pages/Projectdetails";
import { Teams } from "./Pages/Teams";
import { Teamdetails } from "./Pages/Teamdetails";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Error404 } from "./Pages/Error404";
import { Layout } from "./Includes/Layout";

import "./App.css";
import { Projectdemo } from "./Pages/Projectdemo";
import { Projectdatas, Projectdetailsdemo } from "./Pages/Projectdatas";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error404 />,
    children: [
      { index: true, element: <Home /> },
      { path: "aboutus", element: <Aboutus /> },
      { path: "services", element: <Services /> },
      { path: "servicedetails", element: <Servicedetails /> },
      { path: "contactus", element: <Contactus /> },
      { path: "projects", element: <Projects /> },
      // { path: "projectdetails", element: <Projectdetails /> },
      // { path: "/projects/:id", component: <Projects /> },
      { path: "teams", element: <Teams /> },
      { path: "teamdetails", element: <Teamdetails /> },
      { path: "projects", element: <Projectdemo /> },
      // { path: "projectd/:id", element: <Projectdatas /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
