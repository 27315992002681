import React, { useEffect } from "react";
import { Pagetitle } from "../Components/Pagetitle";

export const Servicedetails = () => {
  useEffect(() => {
    document.title = "Digital Signage | Big Format Printing";
  });

  return (
    <>
      <Pagetitle {...{ name: "Big Format Printing" }} />

      <div className="site-main">
        <div className="prt-row sidebar prt-sidebar-right clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content-area">
                <div className="prt-service-single-content-area">
                  <div className="prt_single_image-wrapper mb-40 res-575-mb-20">
                    <img
                      width="1300"
                      height="500"
                      className="img-fluid"
                      src="./images/banners/services-03-1300x500.jpg"
                      alt="services-1"
                    />
                  </div>
                  <div className="prt-service-description">
                    <h3>
                      Custom Designed Signage Solutions for Home,Business &
                      Beyond
                    </h3>
                    <p>
                      With our big format printing you can make bold statements.
                      Whether you need banners, posters or signage for trade
                      shows and events. We have the equipment and expertise to
                      deliver best quality prints that demand attention.
                    </p>
                    <div className="row mt-40">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div className="prt-service-single">
                          <h3 className="sub-text-title">
                            LED Digital Signage
                          </h3>
                          <p>
                            You can now stay ahead of the curve with our LED
                            digital signage solutions from dynamic video walls
                            to interactive displays we offer a huge range of LED
                            signage options to help you captivate your target
                            audience and deliver engaging content in any
                            environment.{" "}
                          </p>
                          <div className="pt-27 res-767-pt-0">
                            <h3 className="sub-text-title">
                              Letterhead Design
                            </h3>
                            <p>
                              One Of The Leaders itextile have issue digital
                              sign been supplying fabric material of a high.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div className="prt-service-single">
                          <h3 className="sub-text-title">
                            Neon Signage Boards
                          </h3>
                          <p>
                            One Of The Leaders itextile have issue digital sign
                            been supplying fabric material of a high.{" "}
                          </p>
                          <div className="pt-27 res-767-pt-0">
                            <h3 className="sub-text-title">
                              Print Advertising
                            </h3>
                            <p>
                              One Of The Leaders itextile have issue digital
                              sign been supplying fabric material of a high.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <div className="prt_single_image-wrapper res-991-mt-20">
                          <img
                            width="409"
                            height="250"
                            className="img-fluid"
                            src="./images/services-01.jpg"
                            alt="services-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-30 res-767-mt-20">
                      <h3>Why choose us</h3>
                      <div className="row pt-20">
                        <div className="col-lg-6">
                          <div className="prt_single_image-wrapper res-991-mb-30">
                            <img
                              width="608"
                              height="300"
                              className="img-fluid"
                              src="./images/services-1.jpg"
                              alt="services-1"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="prt-service-single">
                            <p>
                              simply dummy text of the printing and typesetting
                              industry. data standing own Lorem Ipsum has been
                              the industry’s standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                            <div className="row mt-30 res-991-mt-20">
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <ul className="prt-list prt-list-style-icon-01 prt-list-icon-color-grey">
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      Highly skilled team have the
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      of experience share our it
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      Among many criteria, our studio
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      has the must have an advanced
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <ul className="prt-list prt-list-style-icon-01 prt-list-icon-color-grey">
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      LED displays are application
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      Various Digital Displays
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      Fully Software Solutions
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-check"></i>
                                    <span className="prt-list-li-content">
                                      Customer Experience Trends
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-40 res-991-mt-30">
                      <h3>Frequently asked questions</h3>
                      <div className="row mt-20 res-991-mt-0">
                        <div className="col-lg-12 col-md-12">
                          <div className="accordion style1">
                            <div className="toggle prt-toggle_style_classic prt-control-right-true">
                              <div className="toggle-title">
                                <a href="#" className="active">
                                  Which types logo services you provide ?
                                </a>
                              </div>
                              <div className="toggle-content show">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris .
                                </p>
                              </div>
                            </div>

                            <div className="toggle prt-toggle_style_classic prt-control-right-true">
                              <div className="toggle-title">
                                <a href="#">
                                  Kitchen Cabinets Remodeling & Renovation
                                </a>
                              </div>
                              <div className="toggle-content">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culp.
                                </p>
                              </div>
                            </div>

                            <div className="toggle prt-toggle_style_classic prt-control-right-true">
                              <div className="toggle-title">
                                <a href="#">
                                  What does having Managed your services
                                  provider?
                                </a>
                              </div>
                              <div className="toggle-content">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culp.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
