import React from 'react';

export const Titlesection = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-lg-8 col-md-12 m-auto">
                    <div className="section-title title-style-center_text">
                        <div className="title-header">
                            <h3>{props.sectiontitle}</h3>
                            <h2 className="title">{props.title}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
