import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const socialmeadiasection = [
  {
    title: "Corporate Office Address:",
    icon: "./images/icons/office1.png",
    address:
      "A-603, Rustomjee Central Park, opposite Holy Family Church, Chakala, Andheri East, Mumbai, Maharashtra 400069",
    mobile: "9167860213",
  },
  {
    title: "Factory Address:",
    icon: "./images/icons/factory1.png",
    address:
      "U5/5, Plot No - 2, Green Industrial Park, Kaman, Chinchoti Anjur Phata Rd, Poman, Navghar, Maharashtra 401208",
    mobile: "9167860213",
  },
];

export const Socialmediasection = () => {
  return (
    <>
      {socialmeadiasection.map((data, index) => (
        <div className="col-lg-5 col-md-5 col-sm-12" key={index}>
          <ScrollAnimation animateIn="zoomIn">
            <div
              className="featured-icon-box icon-align-before-title style2  hover-effect"
              style={{ padding: "40px" }}
            >
              <div className="featured-title">
                <h4 className="text-center">{data.title}</h4>
                <div className="row">
                  <div className="col-md-3">
                    <img style={{ width: "75px" }} src={data.icon} alt="logo" />
                  </div>
                  <div className="col-md-9">
                    <p>{data.address} </p>
                    <p>
                      <i class="bi bi-telephone-forward-fill"></i>{" "}
                      <a
                        style={{ color: "#818181" }}
                        href="tel:{data.mobile}"
                        target="_blank"
                      >
                        {data.mobile}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </>
  );
};
