import React, { useEffect } from 'react';
import { Pagetitle } from '../Components/Pagetitle';

export const Teamdetails = () => {

    useEffect(() => {
        document.title = "Digital Signage | Team Details"
    });

    return (
        <>

            <Pagetitle {...{ name: 'Team Details' }} />

            <div className="site-main">

                <section className="prt-row bg-layer-equal-height clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="prt-team-member-single-content">
                                    <div className="prt-team-member-single-content-area">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-sm-12">
                                                <div className="featured-thumbnail">
                                                    <img width="450" height="500" className="img-fluid"
                                                        src="./images/team-detail.jpg" alt="image" />
                                                    <div className="prt-team-social-links">
                                                        <ul className="social-icons">
                                                            <li><a href="#" rel="noopener"
                                                                aria-label="twitter"><i className="icon-twitter"></i></a></li>
                                                            <li><a href="#" rel="noopener"
                                                                aria-label="instagram"><i className="icon-instagram"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12">
                                                <div className="featured-content">
                                                    <div className="prt-team-member-single-title-wrapper">
                                                        <h2 className="prt-team-member-title">Elaine Zhao</h2>
                                                        <div className="prt-team-member-single-position">Visual Media</div>
                                                    </div>
                                                    <div className="prt-team-member-featured-desc">
                                                        <p>Our business experience should be encompass everything. This includes
                                                            ads, sales and promotions, social media, your website; your signage. In
                                                            this advancing digital age signage is often an overlook aspect of the
                                                            retail experience.</p>
                                                    </div>
                                                    <div className="featured-content-box-inner">
                                                        <div className="team-detail-info">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                                    <ul className="prt-list style2">
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Phone :</div>
                                                                            <div className="prt-team-list-value"><a
                                                                                href="#">+(123) 4567 895
                                                                                7845</a></div>
                                                                        </li>
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Email :</div>
                                                                            <div className="prt-team-list-value"><a
                                                                                href="#">info@example.com</a>
                                                                            </div>
                                                                        </li>
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Website :</div>
                                                                            <div className="prt-team-list-value"><a target="_blank"
                                                                                href="#">https://abc.com</a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                                    <ul className="prt-list style2">
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Experience :</div>
                                                                            <div className="prt-team-list-value">+05 Years</div>
                                                                        </li>
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Availability :</div>
                                                                            <div className="prt-team-list-value">Full time employee
                                                                            </div>
                                                                        </li>
                                                                        <li className="prt-list-item">
                                                                            <div className="prt-team-list-title">Location :</div>
                                                                            <div className="prt-team-list-value">envato hq monte,
                                                                                924,australia</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="prt-team-member-content">
                                                    <h3 className="team-member-title">Eductaion & skills</h3>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="prt-team-member-border-right">
                                                                <h3 className="sub-team-member-title">Kingston,united states</h3>
                                                                <p>Lorem ipsum dolor sit amet, consectetur sicing elit, sed do
                                                                    eiusmod.</p>
                                                                <h3 className="sub-team-member-title pt-5 res-991-pt-0">Kingston,united
                                                                    states</h3>
                                                                <p>Lorem ipsum dolor sit amet, consectetur sicing elit, sed do
                                                                    eiusmod.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="prt-team-member-border-left">

                                                                <div className="prt-progress-bar style1" data-percent="50%">
                                                                    <div className="progressbar-title">Remodling</div>
                                                                    <div className="progress-bar-inner">
                                                                        <div className="progress-bar progress-bar-color-bar_skincolor">
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress-bar-percent" data-percentage="50">50%</div>
                                                                </div>

                                                                <div className="prt-progress-bar style1" data-percent="85%">
                                                                    <div className="progressbar-title">Logo design</div>
                                                                    <div className="progress-bar-inner">
                                                                        <div className="progress-bar progress-bar-color-bar_skincolor">
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress-bar-percent" data-percentage="85">85%</div>
                                                                </div>

                                                                <div className="prt-progress-bar style1" data-percent="75%">
                                                                    <div className="progressbar-title">Marketing</div>
                                                                    <div className="progress-bar-inner">
                                                                        <div className="progress-bar progress-bar-color-bar_skincolor">
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress-bar-percent" data-percentage="75">75%</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
