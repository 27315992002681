import React, { useEffect } from "react";
import { Pagetitle } from "../Components/Pagetitle";
import { Projectitems } from "../Components/Projectitems";

export const Projects = () => {
  useEffect(() => {
    document.title = "Digital Signage | Projects";
  });

  return (
    <>
      <Pagetitle {...{ name: "Our Project" }} />

      <div className="site-main">
        <Projectitems />
      </div>
    </>
  );
};
