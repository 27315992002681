import React, { useEffect } from 'react'
import { Pagetitle } from '../Components/Pagetitle'

export const Error404 = () => {

    useEffect(() => {
        document.title = "Digital Signage | 404 Error"
    });

    return (
        <>

            <Pagetitle {...{ name: '404 Error' }} />

            <div class="site-main">
                <section class="prt-row error-404">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="page-content-main text-center">
                                    <div class="page-content">
                                        <div class="page-icon-text">
                                            <i class="fa fa-thumbs-o-down ti-thumb-down"></i>
                                        </div>
                                        <h3>404 Error</h3>
                                        <p>This page may have been moved or deleted. Be sure to check your spelling.</p>
                                    </div>
                                    <div class="prt-404-search-form">
                                        <form method="get" class="search-form" action="#">
                                            <label>
                                                <input type="search" class="search-field" placeholder="Search …" value="" name="s" />
                                            </label>
                                            <input type="submit" class="search-submit" value="Search" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
