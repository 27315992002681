import React from "react";
import { Link } from "react-router-dom";
import { projectdatas } from "../projectdatas";

export const Projectdemo = () => {
  return (
    <>
      <section className="prt-row portfolio-section clearfix">
        <div className="container-fluid">
          <div className="row prt-boxes-spacing-30px">
            {projectdatas.map((element) => {
              return (
                <div className="col-lg-6 col-md-6 col-sm-12 prt-box-col-wrapper">
                  <div className="featured-imagebox featured-imagebox-portfolio style1">
                    <div className="featured-thumbnail">
                      <img
                        width="1920"
                        height="300"
                        src={element.projectimg}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="featured-content">
                      <div className="category">{element.category}</div>
                      <div className="featured-title">
                        <h3>
                          <Link to={`/projectdatas/${element.id}`}>
                            {element.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
