import React, { useEffect } from 'react'
import { Pagetitle } from '../Components/Pagetitle';
import { Teammember } from '../Components/Teammember';


export const Teams = () => {

    useEffect(() => {
        document.title = "Digital Signage | Teams"
    });

    return (
        <>

            <Pagetitle {...{ name: 'Teams' }} />

            <div className="site-main">
                <Teammember />
            </div>
        </>
    )
}
