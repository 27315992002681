import React from 'react';

const teammember = [
    {
        member: './images/team-01.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-02.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-03.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-04.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-05.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-06.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-07.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
    {
        member: './images/team-08.jpg',
        socialicons: [
            {
                class: 'social-facebook',
                icon: 'icon-facebook',
                url: ''
            },
            {
                class: 'social-twitter',
                icon: 'icon-twitter',
                url: ''
            },
            {
                class: 'social-instagram',
                icon: 'icon-instagram',
                url: ''
            },
        ]
    },
]

export const Teammember = () => {
    return (
        <>
            <section className="prt-row grid-section clearfix">
                <div className="container">
                    <div className="row">
                        {teammember.map((teamdata, index) => (
                            <div className="prt-team-box-col-wrapper col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img width="450" height="500" className="img-fluid" src={teamdata.member} alt="image" />
                                        <div className="prt-media-link">
                                            <div className="media-btn"><i className="icon-plus"></i></div>
                                            <ul className="social-icons list-inline">
                                                {teamdata.socialicons.map((icondata, index) => (
                                                    <li className={icondata.class}><a href={icondata.url}><i
                                                        className={icondata.icon}></i></a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <div className="team-position">Visual Media</div>
                                            <h3><a href="/teamdetails">Elaine Zhao</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}
