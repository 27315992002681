import React, { useEffect } from "react";
import { Pagetitle } from "../Components/Pagetitle";
// import { Signagelist } from '../Components/Signagelist';
import { Signagelist2 } from "../Components/Signagelist2";
// import { Servicesmarquee } from '../Components/Servicesmarquee';
// import { Serviceslider } from '../Components/Serviceslider';
import { Servicesinage } from "../Components/Servicesinage";

export const Services = () => {
  useEffect(() => {
    document.title = "Digital Signage | Services";
  });

  return (
    <>
      <Pagetitle {...{ name: "Products" }} />

      <div className="site-main">
        <section
          className="prt-row about-section clearfix"
          style={{ paddingBottom: "40px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="section-title">
                  <div className="title-header">
                    <h2 className="title pro-title">
                      Experienced sign and graphic professionals
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="section-title">
                  <div className="title-desc">
                    <h3
                      className="sub-title mt-10 text-center"
                      style={{ fontSize: "32px" }}
                    >
                      Digital Signage since 2023
                    </h3>
                    <p className="text-center">
                      Since 2023, Digital Signage Services brings more than 1
                      years of experience in design, manufacture & installation
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Servicesinage />

            {/* <Serviceslider />

            <Servicesmarquee /> */}
          </div>
        </section>

        <Signagelist2 />

        {/* <Signagelist /> */}

        {/* <Blog /> */}
      </div>
    </>
  );
};
