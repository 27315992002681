import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Values = () => {
    const imgdetail = {
        img: "./images/values.jpg",
    };
    return (
        <section className="" style={{ paddingTop: '100px' }}>
            <div className="container">
                <div className="row">
                    <h2 className="text-center">Our Values</h2>

                    <div className="col-md-6 how-img">
                        <ScrollAnimation animateIn="fadeInLeft">
                            <img
                                src={imgdetail.img}
                                className=" rounded mb-30 img-fluid"
                                alt=""
                            />
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeInRight">
                            <p>
                                At Digital Signage Company, our values drive everything we do:
                            </p>
                            <p>
                                <b>Innovation: </b>We continuously push the boundaries of technology and creativity to deliver cutting-
                                edge digital signage solutions.
                            </p>
                            <p>
                               <b> Quality: </b>We are committed to excellence, ensuring every project meets the highest standards of
                                performance and reliability.
                            </p>
                            <p>
                                <b>Customer Focus: </b>Our clients are at the heart of our business. We prioritize their needs and work
                                collaboratively to exceed their expectations.
                            </p>
                            <p>
                                <b>Integrity: </b>We conduct our business with honesty and transparency, building trust with our clients
                                and partners.
                            </p>
                            <p>
                                <b>Sustainability: </b>We are dedicated to creating eco-friendly solutions that minimize environmental
                                impact and promote sustainability.
                            </p>
                            <p>
                                <b>Teamwork: </b>We believe in the power of collaboration, fostering a supportive and inclusive work
                                environment where every voice is valued.
                            </p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    );
};
