import React from "react";
import CountUp from "react-countup";

const counter = [
  {
    datato: "29",
    class: "prt-fid inside style1",
    digit: "29",
    ml12: "",
    title: "State covered",
  },
  {
    datato: 1,
    class: "prt-fid inside style1",
    digit: "1",
    ml12: "k+",
    title: "Happy customers",
  },
  {
    datato: "100",
    class: "prt-fid inside style1 last-child",
    digit: "100",
    ml12: "+",
    title: "Project finished",
  },
];

export const Counter = () => {
  const duration = 10;

  return (
    <>
      <div className="mt-45 res-1199-mt-30">
        <div className="row">
          {counter.map((counter, index) => (
            <div className="col-lg-4 col-md-4 col-sm-4" key={index}>
              <div className={counter.class}>
                <div className="prt-fid-contents text-left">
                  <h4 className="prt-fid-inner text-center">
                    <CountUp
                      start={0}
                      end={parseInt(counter.datato)}
                      duration={duration}
                      suffix={counter.ml12}
                    />
                  </h4>
                  <h3 className="prt-fid-title text-center">{counter.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Counter;
