import React, { useEffect } from "react";
import Brandlogo from "../Components/Brandlogo";
import { Counter } from "../Components/Counter";
import { Homepagebanner } from "../Components/Homepagebanner";
import ScrollAnimation from "react-animate-on-scroll";
import { Homesignage } from "../Components/Homesignage";

export const Home = () => {
  useEffect(() => {
    document.title = "Digital Signage | Home";
  });
  return (
    <>
      <Homepagebanner />
      {/* New Section */}
      <section className="container mt-30 clearfix">
        <div className="row">
          <div className="col-lg-6 mt-20" style={{ alignContent: "center" }}>
            <ScrollAnimation animateIn="zoomIn">
              <h2 className="fw-bold mb-4 text-center">
                Digitized engagements that drive results
              </h2>
              <p className="text-center">
                Deploy digital signage that enriches customer and employee
                experiences.
              </p>
            </ScrollAnimation>
          </div>

          {/* Right Boxes Section */}
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-6 mb-4">
                <ScrollAnimation animateIn="fadeInRight">
                  <div className="card border rounded shadow-sm hover-effect custom-card">
                    <div className="card-body">
                      <h4 className="card-title fw-bold">
                        Captivate Customers
                      </h4>
                      <p className="card-text">
                        Showcase enticing offers and product highlights to boost
                        sales.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 mb-4">
                <ScrollAnimation animateIn="fadeInRight">
                  <div className="card border rounded shadow-sm hover-effect custom-card">
                    <div className="card-body">
                      <h4 className="card-title fw-bold">Engage Employees</h4>
                      <p className="card-text">
                        Share company news and updates to foster a connected
                        team.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 mb-4">
                <ScrollAnimation animateIn="fadeInRight">
                  <div className="card border rounded shadow-sm hover-effect custom-card">
                    <div className="card-body">
                      <h4 className="card-title fw-bold">
                        Modernize Your Menu
                      </h4>
                      <p className="card-text">
                        Dynamic digital menu boards make it easy to engage
                        customers.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 mb-4">
                <ScrollAnimation animateIn="fadeInRight">
                  <div className="card border rounded shadow-sm hover-effect custom-card">
                    <div className="card-body">
                      <h4 className="card-title fw-bold">Guide with Ease</h4>
                      <p className="card-text">
                        Clear wayfinding solutions ensure seamless navigation
                        for all.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of New Section */}

      <div className="site-main">
        <section className="prt-row about-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <ScrollAnimation animateIn="zoomIn">
                  <div className="section-title">
                    <div className="title-header">
                      <h2 className="title text-center">
                        Why does digital signage matters?
                      </h2>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-5 col-md-12">
                <ScrollAnimation animateIn="zoomIn">
                  <div className="section-title">
                    <div className="title-desc">
                      <p style={{ textAlign: "justify" }}>
                        In the world loaded with information traditional
                        advertising methods generally failed to capture the
                        attention of modern clients. Digital signage offers a
                        dynamic solution to this problem by using eye-catching
                        visuals interactive features and real time updates to
                        deliver messages that connect with your target audience.
                        Whether you are looking forward to promoting your
                        products in shared important announcements or improving
                        the ambiance of your space our digital signage provides
                        you with a versatile platform to achieve your
                        objectives.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-8">
                <div className="position-relative res-991-pl-0">
                  <div className="about-evant">
                    <div className="prt_single_image-wrapper position-relative z-index-2">
                      <img
                        className="img-fluid"
                        src="./images/digital_welcome_1.jpg"
                        alt="Image"
                      />
                    </div>
                    <div className="marquee-overlay-text">
                      <div className="marquee-block">
                        <div className="marquee style2 overflow-hidden">
                          {/* <Marqueecontent /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="marquee-block marquee-overlay-text2">
                    <div className="marquee style2">
                      {/* <Marqueecontent /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8">
                <div className="">
                  <ScrollAnimation animateIn="zoomIn">
                    <h3 className="sub-title">
                      Transforming your vision to reality
                    </h3>
                    <p style={{ textAlign: "justify" }}>
                      We specialize in delivering best signage solutions
                      tailored to meet your unique requirements. Whether you
                      want to improve your brand presence or you are just an
                      individual seeking creative design solutions. Our team is
                      always here to help you at every step of the way. From
                      commercial signage solutions to LED digital displays we
                      offer a huge range of services to meet your unique
                      requirements.
                    </p>
                  </ScrollAnimation>
                  <a
                    className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-dark mt-23 res-1199-mt-10"
                    href="/contactus"
                  >
                    Contact Us
                  </a>
                  <Counter />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Homesignage />
        <Brandlogo />
      </div>
    </>
  );
};
