import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Pagetitle } from "../Components/Pagetitle";
import { Socialmediasection } from "../Components/Socialmediasection";
import config from "../constant/config.json";

export const Contactus = () => {
  const error = {
    color: "red",
  };

  const [loading, setLoading] = useState(false);
  const [captchaNumbers, setCaptchaNumbers] = useState({ num1: 0, num2: 0 });
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaValidation, setCaptchaValidation] = useState(true);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaNumbers({ num1, num2 });
    setCaptchaAnswer("");
  };

  const [formMessage, setFormMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phoneNumber: Yup.string()
        .matches(/^\+?\d{10,12}$/, "Invalid phone number")
        .required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      const correctCaptchaAnswer = captchaNumbers.num1 + captchaNumbers.num2;

      if (parseInt(captchaAnswer) !== correctCaptchaAnswer) {
        setCaptchaValidation(false);
        console.log("Captcha validation failed. Please try again.");
        generateCaptcha();
        return;
      }

      setCaptchaValidation(true);
      setLoading(true);

      try {
        console.log("Form submitted:", values);
        await axios.post(`${config.API_URL}api/form/submit`, values);
        formik.resetForm();
        setFormMessage("Form submitted successfully!");
        setLoading(false);
      } catch (error) {
        setIsFailed(true);
        console.error("Error submitting data:", error);
        setFormMessage("Failed to submit form.");
        setLoading(false);
      }
      generateCaptcha();
    },
  });

  const handleCaptchaChange = (e) => {
    setCaptchaAnswer(e.target.value);
  };

  useEffect(() => {
    document.title = "Digital Signage | Contact Us";
  }, []);

  return (
    <>
      <Pagetitle {...{ name: "Contact Us" }} />

      <div className="site-main">
        <section
          className="prt-row contect-section clearfix"
          style={{ padding: "65px 0px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="map-contect-form position-relative">
                  <div className="section-title">
                    <div className="title-header">
                      <h2 className="title text-center">
                        Have any project in mind? Let’s work together
                      </h2>
                    </div>
                  </div>
                  <form
                    className="query_form wrap-form clearfix res-991-mt-0 position-relative"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      {formMessage && (
                        <div style={{ padding: "0px 15px" }}>
                          <p
                            className="form-message"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              color: "black",
                              background: isFailed
                                ? "rgba(245, 39, 39, 0.45)"
                                : "rgba(46, 245, 39, 0.45)",
                              borderRadius: 5,
                              border: "1px solid gray",
                            }}
                          >
                            {formMessage}
                          </p>
                        </div>
                      )}

                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="name"
                              type="text"
                              placeholder="Your Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{ color: "black" }}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <span className="error" style={error}>
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="phoneNumber"
                              type="text"
                              placeholder="Your Phone Number"
                              value={formik.values.phoneNumber}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{ color: "black" }}
                            />
                            {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                              <span className="error" style={error}>
                                {formik.errors.phoneNumber}
                              </span>
                            ) : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="email"
                              type="text"
                              placeholder="Your Email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{ color: "black" }}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <span className="error" style={error}>
                                {formik.errors.email}
                              </span>
                            ) : null}
                          </span>
                        </label>
                      </div>

                      <div className="text-dark mt-3">
                        <label className="form-label fs-6" htmlFor="captcha">
                          Resolve the captcha below:
                        </label>
                        <div className="d-flex align-items-center">
                          <p className="mt-3 me-2 text-dark">
                            {captchaNumbers.num1} + {captchaNumbers.num2} =
                          </p>
                          <input
                            type="text"
                            id="captcha"
                            name="captcha"
                            value={captchaAnswer}
                            onChange={handleCaptchaChange}
                            className={`form-control mt-1 ${
                              !captchaValidation ? "is-invalid" : ""
                            } rounded w-auto`}
                            style={{ maxWidth: "60px", padding: "2px" }}
                          />
                        </div>
                        {!captchaValidation && (
                          <div className="invalid-feedback">
                            Captcha is incorrect. Try again.
                          </div>
                        )}
                      </div>

                      <div className="col-md-12 m-0">
                        <button
                          className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-dark mt-25"
                          type="Submit"
                        >
                          {loading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div id="google_map" className="google_map">
                  <div className="map_container">
                    <div>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241075.16703500753!2d72.7133421327087!3d19.247568676624603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a5002f079bab%3A0xb56ef2ba6128cb14!2sOttoedge%20Factory%20Fibre%20Laser!5e0!3m2!1sen!2sin!4v1714992008406!5m2!1sen!2sin"
                        height="565"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="social-section clearfix">
          <div className="container">
            <div className="row justify-content-evenly">
              <Socialmediasection />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
