import React, { useState } from "react";
import axios from "axios";
import config from "../constant/config.json";

export const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const [formMessage, setFormMessage] = useState("");

  const validateForm = (formData) => {
    let errors = {};

    // Name validation
    if (typeof formData.name === "string" && !formData.name.trim()) {
      errors.name = "Name is required";
    }

    // Phone validation
    if (
      typeof formData.phoneNumber === "string" &&
      !formData.phoneNumber.trim()
    ) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Set loading to true
      try {
        console.log("Form submitted:", formData);
        await axios.post(`${config.API_URL}api/newsletter/contactus`, formData);
        setFormData({
          name: "",
          phoneNumber: "",
        });
        setErrors({});
        setFormMessage("Form submitted successfully!");
        setLoading(false); // Set loading to false after successful submission
      } catch (error) {
        console.error("Error submitting data:", error);
        setFormMessage("Failed to submit form.");
        setLoading(false); // Set loading to false if there's an error
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]:
        value.trim() === ""
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : "",
    });
  };

  const handlePhoneInput = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (value !== numericValue) {
        setErrors({
          ...errors,
          [name]: "Phone number must contain only numbers",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });
      }
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    }
  };

  return (
    <>
      <footer className="footer prt-bg bg-base-dark widget-footer clearfix">
        <div className="prt-row-wrapper-bg-layer"></div>
        <div className="first-footer">
          <div className="container">
            <div className="row">
              {/* Column 1 - Logo and Description */}
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget-box">
                  <img
                    style={{ padding: "0px 20px" }}
                    src="images/logos/digital_signage_logo.png"
                    alt="Logo"
                  />
                  <br />
                  <br />
                  <p className="prt-numbertext-p">
                    From Vision to Reality, We've Got You Covered. Experience
                    the Difference with Our Digital Signage Solutions.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget-box">
                  <h3
                    className="widget-title-h3 h3-footer"
                    style={{ color: "#f7db05" }}
                  >
                    Get In Touch
                  </h3>
                  <div className="widget_nav_menu">
                    <div className="textwidget-widget-text">
                      <div className="prt-numbertext">
                        <h4>INDIA</h4>
                        <p className="prt-numbertext-p">
                          <a
                            href="tel:9167860213"
                            className="prt-numbertext-p"
                            target="_blank"
                          >
                            +91 91678 60213
                          </a>
                        </p>

                        <p className="prt-numbertext-p">
                          DIGITAL SIGNAGE COMPANY<br></br> [OTTOEDGE GROUP]
                        </p>
                        <p className="prt-numbertext-p">
                          U5/5, Plot No - 2, Green Industrial Park, Kaman,
                          Chinchoti Anjur Phata Rd, Poman, Navghar, Maharashtra
                          401208
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-add"
                style={{ marginTop: "38px" }}
              >
                <div className="footer-widget-box">
                  <h3 className="widget-title-h3"></h3>
                  <div className="widget_nav_menu">
                    <div className="textwidget-widget-text">
                      <div className="prt-numbertext">
                        <h4>DUBAI</h4>
                        <p className="prt-numbertext-p">
                          <a
                            href="tel:9167860213"
                            className="prt-numbertext-p"
                            target="_blank"
                          >
                            +971 50 271 7265
                          </a>
                        </p>
                        {/* <h3 className="prt-numbertext-h3">
                          <a
                            href="mailto:info@digitalsignagecompany.in"
                            className="prt-numbertext-p"
                            target="_blank"
                          >
                            info@digitalsignagecompany.in
                          </a>
                        </h3> */}
                        <p className="prt-numbertext-p">
                          DIGITAL SIGNAGE COMPANY<br></br> [OTTOEDGE GROUP]
                        </p>
                        <p className="prt-numbertext-p">
                          Boulevard Plaza Tower 2 Floor 22 Sheikh Mohammed bin
                          Rashid Boulevard Dubai - United Arab Emirates
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Column 4 - Contact Form */}
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget-box">
                  <h3 className="widget-title-h3" style={{ color: "#f7db05" }}>
                    Contact Us
                  </h3>
                  <form onSubmit={handleSubmit}>
                    {formMessage && (
                      <p className="form-message">{formMessage}</p>
                    )}
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.name && "is-invalid"
                        }`}
                        placeholder="Enter your name"
                      />
                      {errors.name && (
                        <span className="error-text">{errors.name}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handlePhoneInput}
                        // onInput={handlePhoneInput}
                        className={`form-control ${
                          errors.phoneNumber && "is-invalid"
                        }`}
                        placeholder="Enter your phoneNumber number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                      {errors.phoneNumber && (
                        <span className="error-text">{errors.phoneNumber}</span>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="prt-btn prt-btn-size-sm prt-btn-shape-round prt-btn-style-fill prt-btn-color-white mt-25"
                    >
                      {loading ? "Submitting..." : "Submit"}
                      {/* Submit */}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-footer-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright">
                  <p>
                    Copyright © 2024 <a href="#">Digital signage </a>
                    is Owned by{" "}
                    <a href="https://ottoedge.com/">Ottoedge Services LLP </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a id="totop" to="#top" style={{ cursor: "pointer" }}>
        <i className="icon-angle-up"></i>
      </a>
    </>
  );
};
