import React from "react";
import { Link } from "react-router-dom";

const projectitem = [
  {
    projectimg: "./images/portfolio-1-1920x300.jpg",
    category: "Creative",
    projecturl: "/projectdetails",
    title: "Digital logo designs",
    id: 1,
  },
  {
    projectimg: "./images/portfolio-2-1920x300.jpg",
    category: "Banners, Creative",
    projecturl: "/projectdetails",
    title: " Companylogo designs",
    id: 2,
  },
  {
    projectimg: "./images/portfolio-03-1920x300.jpg",
    category: "Printing",
    projecturl: "/projectdetails",
    title: "Company branding",
    id: 3,
  },
  {
    projectimg: "./images/portfolio-04-1920x300.jpg",
    category: "Creative, Residential",
    projecturl: "/projectdetails",
    title: "Media art design",
    id: 4,
  },
  {
    projectimg: "./images/portfolio-05-1920x300.jpg",
    category: "Printing",
    projecturl: "/projectdetails",
    title: "Business card design",
    id: 5,
  },
  {
    projectimg: "./images/portfolio-06-1920x300.jpg",
    category: "Banners",
    projecturl: "/projectdetails",
    title: "Brand logo designs",
    id: 6,
  },
];

export const Projectitems = () => {
  return (
    <>
      <section className="prt-row portfolio-section clearfix">
        <div className="container-fluid">
          <div className="row prt-boxes-spacing-30px">
            {projectitem.map((projectdata, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 prt-box-col-wrapper"
                key={index}
              >
                <div className="featured-imagebox featured-imagebox-portfolio style1">
                  <div className="featured-thumbnail">
                    <img
                      width="1920"
                      height="300"
                      src={projectdata.projectimg}
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">{projectdata.category}</div>
                    <div className="featured-title">
                      <h3 style={{color: "white"}}>
                        {/* <Link to={`/projectd/${projectdata.id}`}> */}
                          {projectdata.title}
                        {/* </Link> */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
