import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const Mission = () => {
  const imgdetail = {
    img: "./images/mission_values.jpg",
  };
  return (
    <section className="" style={{ paddingTop: "100px" }}>
      <div className="container">
        <div className="row">
          <h2 className="text-center">Our Mission</h2>

          <div className="col-md-6 how-img">
            <ScrollAnimation animateIn="fadeInLeft">
              <img
                src={imgdetail.img}
                className=" rounded mb-30 img-fluid"
                alt=""
              />
            </ScrollAnimation>
          </div>
          <div className="col-md-6 mt-30">
            <ScrollAnimation animateIn="fadeInRight">
              <p className="text-center mt-30">
                At Digital Signage Company, our mission is to revolutionize the
                way businesses communicate by providing innovative, high-impact
                digital display solutions. We aim to empower our clients to
                engage their audiences with compelling, dynamic content that
                informs, inspires, and drives action. Through cutting-edge
                technology, exceptional creativity, and unwavering dedication to
                quality, we strive to transform spaces and enhance experiences,
                one screen at a time.
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};
