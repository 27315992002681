import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const Homesignage = () => {
  const homeslider = [
    {
      service: "./images/signagelists/led_digital_signage.jpg",
      title: "LED Digital Signage",
    },
    {
      service: "./images/signagelists/commercial_signage.jpg",
      title: "Commercial Signage",
    },
    {
      service: "./images/signagelists/dimensinal_signage.jpg",
      title: "Dimensional Signage",
    },
    {
      service: "./images/signagelists/road_signage.jpg",
      title: "Road Signage",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const shouldAutoplay = false;

  const options = {
    responsive: {
      0: {
        items: 1,
      },
      678: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
    autoplay: shouldAutoplay,
    loop: true,
    dots: false,
    nav: true, // Show navigation icons
    navText: [
      '<i class="fa fa-angle-left"  aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    margin: 20,
    slideSpeed: 3000,
  };

  return (
    <div>
      <OwlCarousel className="owl-theme" {...options}>
        {homeslider.map((item, index) => (
          <div className="item" key={index}>
            <div className="featured-imagebox featured-imagebox-service style2 hover-effect">
              <div className="featured-thumbnail">
                <img
                  width="390"
                  height="440"
                  className="img-fluid"
                  src={item.service}
                  alt="services-img"
                />
              </div>
              <div className="featured-content">
                <div className="featured-title">
                  <h4>
                    <a
                      style={{ color: "white", marginBottom: "0px!important" }}
                      href={item.url}
                    >
                      {item.title}
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};
