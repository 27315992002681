import React from "react";

export const Pagetitle = (props) => {
  return (
    <>
      <div className="prt-page-title-row">
        <div className="prt-page-title-row-inner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="prt-page-title-row-heading">
                  <div className="page-title-heading">
                    <h2 className="title" style={{ color: "#f7db05" }}>
                      {props.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
