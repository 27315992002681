import React, { useState } from "react";

const faqdetails2 = [
  {
    faq2: [
      {
        question: "What exactly is digital signage?",
        answer:
          "Digital signage refers to using digital displays, such as LCD, LED, or projection screens, to show multimedia content for informational or advertising purposes.",
      },
      {
        question:
          "How can digital signage benefit businesses or organizations?",
        answer:
          "Digital signage can benefit your business by enhancing brand awareness, improving communication with customers or employees, increasing engagement, and driving sales or other desired actions.",
      },
      {
        question:
          "Can digital signage be customized to fit my specific needs or branding?",
        answer:
          "Absolutely! We offer customization options for digital signage content, design, and hardware to ensure that your digital signage solution aligns with your brand identity and meets your specific requirements.",
      },
      {
        question:
          "What kind of technical support do you provide after installation?",
        answer:
          "We provide comprehensive technical support services to ensure your digital signage solution operates smoothly. Our support team is available to assist with troubleshooting, software updates, and any other issues that may arise.",
      },
    ],
  },
];

export const Faq2 = () => {
  const [activeIndex, setActiveIndex] = useState(
    Array(faqdetails2.length).fill(null)
  );

  const toggleAccordion = (faqIndex, questionIndex) => {
    const newActiveIndex = [...activeIndex];
    newActiveIndex[faqIndex] =
      newActiveIndex[faqIndex] === questionIndex ? null : questionIndex;
    setActiveIndex(newActiveIndex);
  };

  return (
    <section
      className="prt-row faq-service clearfix"
      style={{ padding: "0px 0 79px" }}
    >
      <h2 className="text-center">FAQ</h2>
      <div className="container">
        <div className="row justify-content-center">
          {faqdetails2.map((faqItem, faqIndex) => (
            <div className="col-lg-11" key={faqIndex}>
              <div className="accordion">
                {faqItem.faq2.map((faqData, questionIndex) => (
                  <div
                    className="toggle prt-toggle_style_classic prt-control-right-true"
                    key={questionIndex}
                  >
                    <div
                      className="toggle-title"
                      onClick={() => toggleAccordion(faqIndex, questionIndex)}
                    >
                      <a href="#" className="faq-text faq-t">
                        {faqData.question}
                      </a>
                    </div>
                    <div
                      className={`toggle-content ${
                        activeIndex[faqIndex] === questionIndex
                          ? "show"
                          : "hide"
                      }`}
                    >
                      <p>{faqData.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
