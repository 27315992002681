export const projectdatas = [
  {
    id: 1,
    projectimg: "./images/portfolio-1-1920x300.jpg",
    category: "Creative",
    projecturl: "/projectdetails",
    title: "Digital logo designs",
  },
  {
    id: 2,
    projectimg: "./images/portfolio-2-1920x300.jpg",
    category: "Banners, Creative",
    projecturl: "/projectdetails",
    title: " Companylogo designs",
  },
  {
    id: 3,
    projectimg: "./images/portfolio-03-1920x300.jpg",
    category: "Printing",
    projecturl: "/projectdetails",
    title: "Company branding",
  },
  {
    id: 4,
    projectimg: "./images/portfolio-04-1920x300.jpg",
    category: "Creative, Residential",
    projecturl: "/projectdetails",
    title: "Media art design",
  },
  {
    id: 5,
    projectimg: "./images/portfolio-05-1920x300.jpg",
    category: "Printing",
    projecturl: "/projectdetails",
    title: "Business card design",
  },
  {
    id: 6,
    projectimg: "./images/portfolio-06-1920x300.jpg",
    category: "Banners",
    projecturl: "/projectdetails",
    title: "Brand logo designs",
  },
];
